import * as APIt from '../../API';
import {
    DeviceMutationResponseInterface,
    Schedule,
    ScheduleRow,
    ScheduleTargetInputV1,
    ScheduleTargetInputV2,
} from 'src/utils/SilencioTypes';
import {
    DeviceSources,
    Modes,
    OnguardDelay,
    OnguardReaderMaskActionTypes,
    Paths,
    ScheduleTargets,
    URLS,
} from 'src/constants/Constants';
import {
    fetchDevice,
    setReaderMode,
    setInputMask,
    setOnguardReaderMasking,
    setReaderDoorForcedMask,
    setReaderDoorHeldMask,
} from 'src/utils/DevicesUtils';
import { DateTime } from 'luxon';
import { SelectProps } from '@amzn/awsui-components-react';
import { SilencioDevice } from "src/utils/SilencioTypes";
import { debug } from '../../utils/commonUtils';
import { getKeywordTranslationKey } from 'src/utils/commonUtils';

const daysMap: Record<string, string> = {
    '*': 'day',
    '1': 'Sunday',
    '2': 'Monday',
    '3': 'Tuesday',
    '4': 'Wednesday',
    '5': 'Thursday',
    '6': 'Friday',
    '7': 'Saturday',
    '2-6': 'weekday',
};

const monthsMap: Record<string, string> = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December'
};

export const getActionOptions = (
    bundle: { getMessage: (id: string) => string; formatMessage: (id: string, ...args: any) => string; }
): any => {
    return [
        {
            label: bundle.getMessage('inputs'),
            options: [
                { label: bundle.getMessage('mask'), value: 'Mask' },
                { label: bundle.getMessage('unmask'), value: 'Unmask' },
            ]
        },
        {
            label: bundle.getMessage('readers-alarms'),
            options: [
                { label: bundle.getMessage('mask-door-forced-open'), value: 'Mask Door Forced Open' },
                { label: bundle.getMessage('unmask-door-forced-open'), value: 'Unmask Door Forced Open' },
                { label: bundle.getMessage('mask-door-held-open'), value: 'Mask Door Held Open' },
                { label: bundle.getMessage('unmask-door-held-open'), value: 'Unmask Door Held Open' },
            ]
        },
        {
            label: bundle.getMessage('set-reader-mode'),
            options: Modes.filter((m: SelectProps.Option) => (m.value !== 'Facility Code' && m.value !== 'Unknown'))
                .map((m: SelectProps.Option) => {
                    return {
                        label: bundle.getMessage(getKeywordTranslationKey(m.value)),
                        value: m.value
                    }
                })
        },
    ]
};

const chooseDevicesToSearch = (
    inputDevices: SilencioDevice[],
    path: string,
    readersAlarmsDevices: SilencioDevice[],
    readersModesDevices: SilencioDevice[]): SilencioDevice[] => {
    if (path === Paths.InputMask) {
        return inputDevices;
    } else if (path === Paths.DeviceMode) {
        return readersModesDevices;
    } else {
        return readersAlarmsDevices;
    }
}

/**
 * This function takes in a schedule and the lists of devices in redux and extracts a schedule row for display.
 * The schedule "EntityName" will come from either a device or masking group depending on the schedule target input.
 * If the schedule target is the PACSDataAPI lambda the target input is treated as an Onguard Reader masking event.
 * If the schedule target is the UnicornPACSAPIv2 lambda the target input is treated as the type ScheduleTargetInputV1.
 * Otherwise the target is treated as the type ScheduleTargetInputV2.
 * 
 * @param inputDevices Array of SilencioDevice.
 * @param readersAlarmsDevices Array of SilencioDevice.
 * @param readersModesDevices Array of SilencioDevice.
 * @param schedule The schedule to be parsed.
 * @returns A parsed ScheduleRow object.
 */
export const extractScheduleRow = async (
    inputDevices: SilencioDevice[],
    readersAlarmsDevices: SilencioDevice[],
    readersModesDevices: SilencioDevice[],
    schedule: Schedule,
    siteRegion: string | null): Promise<ScheduleRow> => {
    debug(`extractScheduleRow() schedule target input: ${schedule.Target?.Input}`);

    let device: SilencioDevice | null | undefined;
    let masked: boolean | null | undefined;
    let maskingGroupName: string | null | undefined;
    let maskingGroupId: number | null | undefined;
    let mode: string | null | undefined;
    let defaultEntityName: string = 'Device not found';

    const path: string = JSON.parse(schedule.Target!.Input!).path!;

    if (schedule.Target!.Arn!.includes(ScheduleTargets.PACSDataAPI)) {
        // Extract schedule target input information from the Onguard Reader Alarm Masking query string parameters in the path
        const path: string = JSON.parse(schedule.Target!.Input!).path!;
        const pathRegex = /stage.*SEGMENTID=(?<segmentId>\d+)&PANELID=(?<parentDeviceId>\d+)&DEVICEID=(?<childDeviceId>\d+)&EXECUTION_VALUE=(?<executionValue>\d).*/;
        const { segmentId, parentDeviceId, childDeviceId, executionValue } = pathRegex.exec(path)!.groups!;

        device = readersAlarmsDevices.find(d => (d.Parent_DeviceID?.toString() == parentDeviceId && d.Child_DeviceID?.toString() == childDeviceId && d.SegmentID?.toString() == segmentId));
        masked = (executionValue == '1');
    } else if (schedule.Target!.Arn!.includes(ScheduleTargets.UnicornPACSAPIv2)) {
        const targetInput = JSON.parse(schedule.Target!.Input!) as ScheduleTargetInputV1;
        masked = targetInput.masked;
        maskingGroupName = targetInput.maskingGroupName;
        mode = targetInput.mode;

        if (!maskingGroupName) {
            const deviceHref = (targetInput.devices && targetInput.devices.length == 1) ? targetInput.devices[0]!.deviceHref : undefined;
            const deviceName = (targetInput.devices && targetInput.devices.length == 1) ? targetInput.devices[0]!.deviceName : undefined;
            if (deviceName) defaultEntityName = `Device not found: ${deviceName}`;
            const devices = chooseDevicesToSearch(inputDevices, path, readersAlarmsDevices, readersModesDevices);

            if (deviceHref) {
                device = devices.find(d => d.device_href === deviceHref);
            } else if (deviceName) {
                device = devices.find(d => d.DeviceName === deviceName);
            }
        }
    } else {
        const targetInput = JSON.parse(schedule.Target!.Input!) as ScheduleTargetInputV2;
        const amzn_key = targetInput.amzn_key;
        masked = targetInput.masked;
        maskingGroupName = targetInput.maskingGroupName;
        maskingGroupId = targetInput.maskingGroupId;
        mode = targetInput.mode;

        if (!maskingGroupName && amzn_key) {
            defaultEntityName = `Device not found: ${amzn_key}`;;
            const devices = chooseDevicesToSearch(inputDevices, path, readersAlarmsDevices, readersModesDevices);
            device = devices.find(d => `${d.Parent_DeviceID}_${d.Child_DeviceID}_${d.Subchild_DeviceID}` === amzn_key);

            if (!device) {
                debug(`extractScheduleRow() device not found in redux, searching amzn_key ${amzn_key} in API in case site has been migrated.`);
                const migratedDevice = await fetchDevice(amzn_key, siteRegion);
                if (migratedDevice) {
                    device = devices.find(d => (d.Parent_DeviceID == migratedDevice.Parent_DeviceID
                        && d.Child_DeviceID == migratedDevice.Child_DeviceID
                        && d.Subchild_DeviceID == migratedDevice.Subchild_DeviceID));
                }
            }
        }
    }

    return {
        Action: getAction(masked, mode, path),
        CreationDate: schedule.CreationDate,
        EntityName: device ? device.DeviceName! : maskingGroupName ?? defaultEntityName,
        EntityId: device ? `${device.Parent_DeviceID}_${device.Child_DeviceID}_${device.Subchild_DeviceID}` : maskingGroupId!,
        FlexibleTimeWindow: schedule.FlexibleTimeWindow,
        FormattedScheduleExpression: formatScheduleExpression(schedule.ScheduleExpression!, schedule.ScheduleExpressionTimezone!),
        LastInvocationResult: schedule.LastInvocationResult,
        LastInvocationRequestId: schedule.LastInvocationRequestId,
        LastInvocationHttpStatus: schedule.LastInvocationHttpStatus,
        LastInvocationTimestamp: schedule.LastInvocationTimestamp,
        Name: schedule.Name,
        PairedReader: device ? device.PairedReader : undefined,
        ScheduleExpression: schedule.ScheduleExpression!,
        ScheduleExpressionTimezone: schedule.ScheduleExpressionTimezone,
        State: schedule.State
    }
}

/**
 * This function takes an AWS EventBridge Scheduler ScheduleExpression as input and formats it to be a more user friendly string.
 * The input can be in the format of "at(yyyy-mm-ddThh:mm:ss)" or "cron(minutes hours day-of-month month day-of-week year)"
 * {@link https://docs.aws.amazon.com/scheduler/latest/UserGuide/schedule-types.html|AWS EventBridge Scheduler Guide}
 *
 * @param input schedule expression string
 * @param timezone schedule expression timezone
 * @returns 
 */
export const formatScheduleExpression = (input: string, timezone: string): string => {
    debug(`formatScheduleExpression() input is ${input}`);
    try {
        if (input.startsWith('at')) {
            const { year, month, day, time } = /at\((?<year>\d+)-(?<month>\d+)-(?<day>\d+)T(?<time>.+)\)/.exec(input)!.groups!;
            return `On ${day} ${monthsMap[month]} ${year} at ${time.slice(0, 5)} ${timezone}`;
        }
        if (input.startsWith('cron')) {
            const { minutes, hours, dayOfWeek } = /cron\((?<minutes>.+)\s(?<hours>.+)\s(.+)\s(.+)\s(?<dayOfWeek>.+)\s(.+)\)/.exec(input)!.groups!;
            return `Every ${daysMap[dayOfWeek]} at ${hours}:${minutes} ${timezone}`;
        }
    }
    catch (error) {
        debug(`formatScheduleExpression() error is ${error}`);
    }
    return input;
}

const getAction = (masked: boolean | null | undefined, mode: string | null | undefined, path: string): string => {
    if (path.startsWith(Paths.OnguardReaderMasking)) {
        if (path.includes(`ACTION_TYPEID=${OnguardReaderMaskActionTypes.doorForcedOpen}`))
            return masked ? 'Mask Door Forced Open' : 'Unmask Door Forced Open';
        return masked ? 'Mask Door Held Open' : 'Unmask Door Held Open';
    }
    switch (path) {
        case Paths.InputMask:
            return masked ? 'Mask' : 'Unmask';
        case Paths.ReaderDoorForced:
            return masked ? 'Mask Door Forced Open' : 'Unmask Door Forced Open';
        case Paths.ReaderDoorHeld:
            return masked ? 'Mask Door Held Open' : 'Unmask Door Held Open';
        case Paths.DeviceMode:
            return mode ?? 'Unknown';
        default:
            return 'Unknown';
    }
}

export const getProgrammingSummaryLink = (): string => {
    let stage = 'prod';
    return stage.toLowerCase() !== 'prod' ? URLS.ProgrammingSummaryGamma : URLS.ProgrammingSummary;
}

/**
 * This function takes an AWS EventBridge Scheduler ScheduleExpression and an IANA timezone as inputs
 * and determines if the schedule can be enabled by checking against the current time (with a 2 minute buffer)
 * to see if the schedule is in the past.
 *  
 * The scheduleExpression can be in the format of "at(yyyy-mm-ddThh:mm:ss)" or "cron(minutes hours day-of-month month day-of-week year)"
 * Note: Returns false to always "allow" cron schedules.
 * 
 * @param scheduleExpression string
 * @param timezone An IANA timezone
 */
export const isScheduleExpressionInPast = (scheduleExpression: string, timezone: string | undefined): boolean => {
    if (scheduleExpression.startsWith('at')) {
        if (!timezone) {
            debug(`isScheduleExpressionInPast() missing timezone`);
            return true;
        }
        try {
            const now = DateTime.now().setZone(timezone);
            now.plus({ minutes: 2 });
            const { date, time } = /at\((?<date>[0-9\-]+)T(?<time>.+)\)/.exec(scheduleExpression)!.groups!;
            debug(`isScheduleExpressionInPast() date: ${date}. time: ${time}`);
            const input = DateTime.fromISO(`${date}T${time.slice(0, 5)}:00`, { zone: timezone });
            debug(`isScheduleExpressionInPast() input: ${input.toISO()}. now: ${now.toISO()}. Result is: ${input < now}`);
            return input < now;
        } catch (error) {
            debug(`isScheduleExpressionInPast() error is ${error}`);
        }
    }
    return false;
}

export const performForScheduleInitialAction = async (devices: SilencioDevice[], deviceType: string, input: APIt.SetScheduleInput | APIt.EditScheduleInput) => {
    let result: DeviceMutationResponseInterface;
    const deviceSummaries: { deviceName: string, deviceHref: string | undefined }[] = [];
    devices.forEach(device => deviceSummaries.push({
        deviceName: device.DeviceName!,
        deviceHref: device.device_href ?? undefined,
    }));
    debug(`performForScheduleInitialAction() performing initial action on devices: ${JSON.stringify(deviceSummaries)}`);
    switch (deviceType) {
        case 'inputs':
            result = await setInputMask(
                input.deviceSource,
                deviceSummaries,
                '',
                input.masked!,
                0,
                input.requestedBy,
                input.siteName,
                input.siteRegion,
            );
            break;
        case 'readersAlarms':
            const onguardSite: boolean = input.deviceSource === DeviceSources.ONGUARD;
            if (onguardSite) {
                const doorForcedMask: boolean = input.path.includes(`ACTION_TYPEID=${OnguardReaderMaskActionTypes.doorForcedOpen}`);
                result = await setOnguardReaderMasking(
                    input.siteRegion,
                    devices,
                    doorForcedMask ? OnguardReaderMaskActionTypes.doorForcedOpen : OnguardReaderMaskActionTypes.doorHeldOpen,
                    input.masked!,
                    input.requestedBy,
                    OnguardDelay,
                );
            } else {
                const doorForcedMask: boolean = input.path === Paths.ReaderDoorForced;
                if (doorForcedMask) {
                    result = await setReaderDoorForcedMask(
                        input.deviceSource,
                        deviceSummaries,
                        '',
                        input.masked!,
                        0,
                        input.requestedBy,
                        input.siteName,
                        input.siteRegion,
                    );
                } else {
                    result = await setReaderDoorHeldMask(
                        input.deviceSource,
                        deviceSummaries,
                        '',
                        input.masked!,
                        0,
                        input.requestedBy,
                        input.siteName,
                        input.siteRegion,
                    );
                }
            }
            break;
        default:
            result = await setReaderMode(
                input.deviceSource,
                deviceSummaries,
                '',
                input.mode!,
                0,
                input.requestedBy,
                input.siteName,
                input.siteRegion,
            );
    }
    if (result.status === 200 || result.status === 202) {
        debug(`performForScheduleInitialAction() initial action successful for: ${JSON.stringify(devices)}`);
    } else {
        debug(`performForScheduleInitialAction() Error: result status code was unsuccessful. Result: ${JSON.stringify(result)}`);
        throw new Error(`performForScheduleInitialAction() Error performing initial action on devices: ${JSON.stringify(deviceSummaries)}`);
    }
}