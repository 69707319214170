import { LocalizationContextBuilder } from "@amzn/arb-tools";
import { PropertyFilterProps } from '@amzn/awsui-components-react/polaris/property-filter';

export const DEFAULT_LOCALE = navigator.language || 'en-US';
export const DEFAULT_LC_BUILDER = new LocalizationContextBuilder().withDefaultLocale(DEFAULT_LOCALE);

export const DelayOptions = [
  { label: 'None', value: '0' },
  { label: '15 seconds', value: '.25' },
  { label: '30 seconds', value: '.5' },
  { label: '1 minute', value: '1' },
  { label: '2 minutes', value: '2' },
  { label: '3 minutes', value: '3' },
  { label: '4 minutes', value: '4' },
  { label: '5 minutes', value: '5' },
];

export const DelimiterRegex = /(\r\n|\r|\n|\s|;|,)+/g;

export enum DeviceTypeTabs {
  inputs = 'inputs',
  readersAlarms = 'readersAlarms',
  readersModes = 'readersModes'
}

export const DeviceTypes = {
  Inputs: ['alarm_panel_input', 'card_reader_aux_input_1', 'card_reader_aux_input_2'],
  Outputs: ['alarm_panel_output', 'card_reader_aux_output_1', 'card_reader_aux_output_2'],
  Readers: ['card_reader']
};

export enum DeviceMutationStatuses {
  notStarted = 'not-started',
  inProgress = 'in-progress',
  failure = 'failure',
  submitted = 'submitted',
  updated = 'updated'
}

export enum DeviceSources {
  KEEP = 'keep',
  ONGUARD = 'onguard',
}

export interface ILanguage {
  id: string;
  text: string;
}

export const Languages: ILanguage[] = [
  {
    id: "en-US",
    text: "English",
  },
  {
    id: "fr-FR",
    text: "French",
  },
  {
    id: "de-DE",
    text: "German",
  },
  {
    id: "es-ES",
    text: "Spanish",
  },
  {
    id: "pt-PT",
    text: "Portuguese",
  },
  {
    id: "it-IT",
    text: "Italian",
  },
  {
    id: "hi-IN",
    text: "Hindi",
  },
  {
    id: "ja-JP",
    text: "Japanese",
  },
  {
    id: "ar-AE",
    text: "Arabic (U.A.E.)",
  },
  {
    id: "ar-SA",
    text: "Arabic (Saudi Arabia)",
  },
  {
    id: "cs-CZ",
    text: "Czech (Czech Republic)",
  },
  {
    id: "en-AU",
    text: "English (Australia)",
  },
  {
    id: "en-CA",
    text: "English (Canada)",
  },
  {
    id: "en-GB",
    text: "English (United Kingdom)",
  },
  {
    id: "en-SG",
    text: "English (Singapore)",
  },
  {
    id: "es-MX",
    text: "Spanish (Mexico)",
  },
  {
    id: "fr-CA",
    text: "French (Canada)",
  },
  {
    id: "nl-NL",
    text: "Dutch (Netherlands)",
  },
  {
    id: "pl-PL",
    text: "Polish (Poland)",
  },
  {
    id: "sk-SK",
    text: "Slovak (Slovakia)",
  },
  {
    id: "tr-TR",
    text: "Turkish (Turkey)",
  },
  {
    id: "zh-CN",
    text: "Chinese (S)",
  },
  {
    id: "zh-TW",
    text: "Chinese (T)",
  },
];

export const ScheduleDurationLimit: number = 1000 * 60 * 60 * 24 * 30;

export const TemplateActionBatchSize: number = 10;

export const DefaultEmailDomain = 'amazon.com';

export const EmailDomains = [
  'amazon.com',
];

export const EmptyPropertyFilterQuery: PropertyFilterProps.Query = { tokens: [], operation: "and" };

export const HOW_MANY_TRACKER = {
  WebsiteAccess_URL_LOCAL: 'https://0s62bmu3aj.execute-api.us-east-1.amazonaws.com/PROD/pixel/tracker?PixelID=806d1a92-a1ad-e955-e540-10aff938b390',
  WebsiteAccess_URL_BETA: 'https://0s62bmu3aj.execute-api.us-east-1.amazonaws.com/PROD/pixel/tracker?PixelID=4783fafd-400e-6ab3-cb4b-8f48fe97e183',
  WebsiteAccess_URL_PROD: 'https://0s62bmu3aj.execute-api.us-east-1.amazonaws.com/PROD/pixel/tracker?PixelID=62930635-58aa-3cbe-62e9-da0d359d05e3'
};

export const Modes = [
  { label: 'Card and Pin', value: 'Card and Pin' },
  { label: 'Card Only', value: 'Card Only' },
  { label: 'Facility Code', value: 'Facility Code' },
  { label: 'Locked', value: 'Locked' },
  { label: 'Pin or Card', value: 'Pin or Card' },
  { label: 'Unlocked', value: 'Unlocked' },
  { label: 'Unknown', value: 'Unknown' },
];

export enum OnguardReaderMaskActionTypes {
  doorForcedOpen = 10,
  doorHeldOpen = 11
}

export const Paths = {
  DeleteMaskGroup: '/query/SilencioV2_Delete_MaskGroup_V2',
  DeviceMode: '/v1/DeviceMode',
  EditMaskGroup: '/query/SilencioV2_Edit_MaskGroup_V2',
  GetDevice: '/query/SilencioV2_Get_Device',
  GetDevices: '/query/SilencioV2_Get_Devices_WithMaskGroup_V4',
  GetDevicesWithReadOnly: '/query/SilencioV2_Get_Devices_WithMaskGroup_WithReadOnly_V1',
  GetLocalIORelations: '/query/SilencioV2_Local_IO_Relation_V1',
  GetSites: '/query/SilencioV2_Get_Sites_V1',
  InputMask: '/v1/InputMask',
  OnguardReaderMasking: '/FlexQuery/SilencioV2_Insert_DHO_DFO_Task',
  OutputCommand: '/v1/OutputCommand',
  ReaderDoorForced: '/v1/ReaderDoorForcedMask',
  ReaderDoorHeld: '/v1/ReaderDoorHeldMask',
  SiteFinder: '/query/SilencioV2_Site_Finder',
  SiteTemplateDevices: '/query/SilencioV2_Get_Devices_ForTemplate_V1',
  UpsertSiteTemplate: '/query/SilencioV2_Edit_SiteStatusTemplate_V1',
}

export const ProdDomain = 'silencio.gso.amazon.dev';

export const URLS = {
  Contact: 'https://w.amazon.com/index.php/SIDE#HContactus',
  EmailNotificationFAQ: 'https://w.amazon.com/bin/view/AccSys/inner/SilencioV2/#HIamgettingamessageIcannotuseemailnotificationsforsomedevices.Why3F',
  GuardianHub: 'https://guardian.gso.amazon.dev/',
  ProgrammingSummaryGamma: 'https://gamma.pacer.gso.amazon.dev/#/Reports/ProgrammingSummary',
  ProgrammingSummary: 'https://pacer.gso.amazon.dev/#/Reports/ProgrammingSummary',
  RiverWorkflow: 'https://river.amazon.com/?org=AccSys&q0=13ee574b-f5c8-4a22-b1e2-386655c2172c',
  SilencioFAQ: 'https://w.amazon.com/bin/view/AccSys/inner/SilencioV2/#HFAQ',
  SiteOpenClose: 'https://w.amazon.com/bin/view/AccSys/inner/SilencioV2/#HSiteTemplates2FQuickActions2FSiteOpenClose',
  UserGuide: 'https://w.amazon.com/bin/view/SIDE/SilencioV2/',
};

export enum ScheduleState {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED'
};

export enum ScheduleTargets {
  DeviceScheduleLambda = 'SilencioV2DeviceScheduleLambda',
  MaskingGroupScheduleLambda = 'SilencioV2MaskingGroupScheduleLambda',
  PACSDataAPI = 'SilencioV2PACSDataAPI', // Deprecated, kept to handle display of legacy schedules in UI
  UnicornPACSAPIv2 = 'SilencioV2UnicornPACSAPIv2', // Deprecated, kept to handle display of legacy schedules in UI
}

export enum TemplateActions {
  Mask = 'Mask',
  Unmask = 'Unmask',
  MaskDoorForcedOpen = 'Mask Door Forced Open',
  UnmaskDoorForcedOpen = 'Unmask Door Forced Open',
  MaskDoorHeldOpen = 'Mask Door Held Open',
  UnmaskDoorHeldOpen = 'Unmask Door Held Open',
  CardAndPin = 'Card and Pin',
  CardOnly = 'Card Only',
  FacilityCode = 'Facility Code',
  Locked = 'Locked',
  PinOrCard = 'Pin or Card',
  Unlocked = 'Unlocked',
}

export enum TemplateTypes {
  open = 'Open',
  close = 'Close',
}

export enum UserActionNames {
  AuthenticateUser = 'Authenticate User',
  AuthenticateUserError = 'Error: Authenticate User',
  CloseSite = 'Close Site',
  CloseSiteError = 'Error: Close Site',
  CloseSiteResult = 'Close Site Result',
  CreateUserPreferences = 'Create User Preferences',
  CreateUserPreferencesError = 'Error: Create User Preferences',
  DeleteMaskingGroup = 'Delete Masking Group',
  DeleteMaskingGroupError = 'Error: Delete Masking Group',
  DeleteMaskingGroupResult = 'Delete Masking Group Result',
  DeleteSchedule = 'Delete Schedule',
  DeleteScheduleError = 'Error: Delete Schedule',
  DeleteScheduleResult = 'Delete Schedule Result',
  EditSchedule = 'Edit Schedule',
  EditScheduleError = 'Error: Edit Schedule',
  EditScheduleResult = 'Update Edit Result',
  FetchUserPreferences = 'Fetch User Preferences',
  FetchUserPreferencesError = 'Error: Fetch User Preferences',
  GetSiteTemplateDevices = 'Get Site Template Devices',
  GetSiteTemplateDevicesError = 'Error: Get Site Template Devices',
  GetSiteTemplateDevicesResult = 'Get Site Template Devices Result',
  ListSchedules = 'List Schedules',
  ListSchedulesError = 'Error: List Schedules',
  ListSchedulesResult = 'List Schedules Result',
  OpenSite = 'Open Site',
  OpenSiteError = 'Error: Open Site',
  OpenSiteResult = 'Open Site Result',
  RefreshSites = 'Refresh Sites',
  RefreshSitesError = 'Error: Refresh Sites',
  RefreshSitesResult = 'Refresh Sites Result',
  SelectMaskingGroup = 'Select Masking Group',
  SelectSite = 'Select Site',
  SelectSiteError = 'Error: Select Site',
  SetInputMask = 'Set Input Mask',
  SetInputMaskError = 'Error: Set Input Mask',
  SetInputMaskResult = 'Set Input Mask Result',
  SetOutputCommand = 'Set Output Command',
  SetOutputCommandError = 'Error: Set Output Command',
  SetOutputCommandResult = 'Set Output Command Result',
  SetReaderDoorHeldMask = 'Set Reader Door Held Mask',
  SetReaderDoorHeldMaskError = 'Error: Set Reader Door Held Mask',
  SetReaderDoorHeldMaskResult = 'Set Reader Door Held Mask Result',
  SetReaderDoorForcedMask = 'Set Reader Door Forced Mask',
  SetReaderDoorForcedMaskError = 'Error: Set Reader Door Forced Mask',
  SetReaderDoorForcedMaskResult = 'Set Reader Door Forced Mask Result',
  SetReaderMode = 'Set Reader Mode',
  SetReaderModeError = 'Error: Set Reader Mode',
  SetReaderModeResult = 'Set Reader Mode Result',
  SetSchedule = 'Set Schedule',
  SetScheduleError = 'Error: Set Schedule',
  SetScheduleResult = 'Set Schedule Result',
  UpdateSchedule = 'Update Schedule',
  UpdateScheduleError = 'Error: Update Schedule',
  UpdateScheduleResult = 'Update Schedule Result',
  UpdateUserPreferences = 'Update User Preferences',
  UpsertMaskingGroup = 'Upsert Masking Group',
  UpsertMaskingGroupError = 'Error: Upsert Masking Group',
  UpsertMaskingGroupResult = 'Upsert Masking Group Result',
  UpsertSiteTemplate = 'Upsert Site Template',
  UpsertSiteTemplateError = 'Error: Upsert Site Template',
  UpsertSiteTemplateResult = 'Upsert Site Template Result',
};

export enum MaskingGroupTypes {
  Inputs = "Input_MaskGroup",
  ReadersAlarms = "ReaderAlarm_MaskGroup",
  ReadersModes = "ReaderMode_MaskGroup"
};

export const OnguardDelay = 120;

export enum PhySecTeams {
  AMER = 'amer-physec',
  APAC = 'pspm-apac',
  EMEA = 'emea-psi'
};

export const Regions = ['AMER', 'APAC', 'EMEA'];

export const SilencioDeviceFilteringProperties: PropertyFilterProps.FilteringProperty[] = [
  {
    key: "DeviceName",
    operators: ["=", "!=", ":", "!:"],
    propertyLabel: "Device Name",
    groupValuesLabel: "Device Names"
  },
  {
    key: "Device_Type",
    operators: ["=", "!=", ":", "!:"],
    propertyLabel: "Device Type",
    groupValuesLabel: "Device Types"
  }
];

export const SiteScheduleLimit = 100;

export const Statuses = [
  { label: 'Masked', value: 'Masked' },
  { label: 'Unmasked', value: 'Unmasked' },
];

export const OutputCommands = [
  { label: 'Pulse', value: 'Pulse' },
  { label: 'Activate', value: 'Activate' },
  { label: 'Deactivate', value: 'Deactivate' },
];