/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUserPrefs = /* GraphQL */ `mutation CreateUserPrefs($input: CreateUserPrefsInput!) {
  createUserPrefs(input: $input) {
    username
    inputs {
      numRecordsPerPage
      emailAddresses
      __typename
    }
    outputs {
      numRecordsPerPage
      emailAddresses
      __typename
    }
    lastSite {
      sitename
      siteRegion
      __typename
    }
    readersAlarms {
      numRecordsPerPage
      emailAddresses
      __typename
    }
    readersModes {
      numRecordsPerPage
      emailAddresses
      __typename
    }
    schedules {
      numRecordsPerPage
      emailAddresses
      visibleColumns
      __typename
    }
    siteTemplates {
      numRecordsPerPage
      visibleColumns
      __typename
    }
    site
    sites {
      sitename
      siteRegion
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserPrefsMutationVariables,
  APITypes.CreateUserPrefsMutation
>;
export const updateUserPrefs = /* GraphQL */ `mutation UpdateUserPrefs($input: UpdateUserPrefsInput!) {
  updateUserPrefs(input: $input) {
    username
    inputs {
      numRecordsPerPage
      emailAddresses
      __typename
    }
    outputs {
      numRecordsPerPage
      emailAddresses
      __typename
    }
    lastSite {
      sitename
      siteRegion
      __typename
    }
    readersAlarms {
      numRecordsPerPage
      emailAddresses
      __typename
    }
    readersModes {
      numRecordsPerPage
      emailAddresses
      __typename
    }
    schedules {
      numRecordsPerPage
      emailAddresses
      visibleColumns
      __typename
    }
    siteTemplates {
      numRecordsPerPage
      visibleColumns
      __typename
    }
    site
    sites {
      sitename
      siteRegion
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserPrefsMutationVariables,
  APITypes.UpdateUserPrefsMutation
>;
export const deleteUserPrefs = /* GraphQL */ `mutation DeleteUserPrefs($input: DeleteUserPrefsInput!) {
  deleteUserPrefs(input: $input) {
    username
    inputs {
      numRecordsPerPage
      emailAddresses
      __typename
    }
    outputs {
      numRecordsPerPage
      emailAddresses
      __typename
    }
    lastSite {
      sitename
      siteRegion
      __typename
    }
    readersAlarms {
      numRecordsPerPage
      emailAddresses
      __typename
    }
    readersModes {
      numRecordsPerPage
      emailAddresses
      __typename
    }
    schedules {
      numRecordsPerPage
      emailAddresses
      visibleColumns
      __typename
    }
    siteTemplates {
      numRecordsPerPage
      visibleColumns
      __typename
    }
    site
    sites {
      sitename
      siteRegion
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserPrefsMutationVariables,
  APITypes.DeleteUserPrefsMutation
>;
export const editSchedule = /* GraphQL */ `mutation EditSchedule(
  $scheduleAction: ScheduleAction
  $input: EditScheduleInput
) {
  editSchedule(scheduleAction: $scheduleAction, input: $input) {
    error
    message
    result {
      scheduleName
      scheduleArn
      __typename
    }
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.EditScheduleMutationVariables,
  APITypes.EditScheduleMutation
>;
export const createUserAction = /* GraphQL */ `mutation CreateUserAction($input: UserActionInput!) {
  createUserAction(input: $input) {
    actionId
    actionName
    timestamp
    username
    parameters
    ttl
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserActionMutationVariables,
  APITypes.CreateUserActionMutation
>;
export const setReaderMode = /* GraphQL */ `mutation SetReaderMode($input: SetReaderModeInput!) {
  setReaderMode(input: $input) {
    devices {
      deviceName
      status
      __typename
    }
    message
    requestUUID
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetReaderModeMutationVariables,
  APITypes.SetReaderModeMutation
>;
export const setInputMask = /* GraphQL */ `mutation SetInputMask($input: SetInputMaskInput!) {
  setInputMask(input: $input) {
    devices {
      deviceName
      status
      __typename
    }
    message
    requestUUID
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetInputMaskMutationVariables,
  APITypes.SetInputMaskMutation
>;
export const setOutputCommand = /* GraphQL */ `mutation SetOutputCommand($input: SetOutputCommandInput!) {
  setOutputCommand(input: $input) {
    devices {
      deviceName
      status
      __typename
    }
    message
    requestUUID
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetOutputCommandMutationVariables,
  APITypes.SetOutputCommandMutation
>;
export const setReaderDoorHeldMask = /* GraphQL */ `mutation SetReaderDoorHeldMask($input: SetReaderDoorHeldMaskInput!) {
  setReaderDoorHeldMask(input: $input) {
    devices {
      deviceName
      status
      __typename
    }
    message
    requestUUID
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetReaderDoorHeldMaskMutationVariables,
  APITypes.SetReaderDoorHeldMaskMutation
>;
export const setReaderDoorForcedMask = /* GraphQL */ `mutation SetReaderDoorForcedMask($input: SetReaderDoorForcedMaskInput!) {
  setReaderDoorForcedMask(input: $input) {
    devices {
      deviceName
      status
      __typename
    }
    message
    requestUUID
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetReaderDoorForcedMaskMutationVariables,
  APITypes.SetReaderDoorForcedMaskMutation
>;
export const setSchedule = /* GraphQL */ `mutation SetSchedule(
  $scheduleAction: ScheduleAction
  $input: SetScheduleInput
) {
  setSchedule(scheduleAction: $scheduleAction, input: $input) {
    error
    message
    result {
      scheduleName
      scheduleArn
      __typename
    }
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetScheduleMutationVariables,
  APITypes.SetScheduleMutation
>;
export const setPACSDataAPIReaderMask = /* GraphQL */ `mutation SetPACSDataAPIReaderMask($path: String!) {
  setPACSDataAPIReaderMask(path: $path) {
    status
    actionid
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetPACSDataAPIReaderMaskMutationVariables,
  APITypes.SetPACSDataAPIReaderMaskMutation
>;
export const updateSchedule = /* GraphQL */ `mutation UpdateSchedule(
  $scheduleAction: ScheduleAction
  $input: UpdateScheduleInput
) {
  updateSchedule(scheduleAction: $scheduleAction, input: $input) {
    error
    message
    result {
      scheduleName
      scheduleArn
      __typename
    }
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateScheduleMutationVariables,
  APITypes.UpdateScheduleMutation
>;
export const upsertMaskingGroup = /* GraphQL */ `mutation UpsertMaskingGroup($path: String!) {
  upsertMaskingGroup(path: $path) {
    Result
    Result_Description
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpsertMaskingGroupMutationVariables,
  APITypes.UpsertMaskingGroupMutation
>;
export const upsertSiteTemplate = /* GraphQL */ `mutation UpsertSiteTemplate($path: String!) {
  upsertSiteTemplate(path: $path) {
    Result
    Result_Description
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpsertSiteTemplateMutationVariables,
  APITypes.UpsertSiteTemplateMutation
>;
export const deleteMaskingGroup = /* GraphQL */ `mutation DeleteMaskingGroup($path: String!) {
  deleteMaskingGroup(path: $path) {
    Result
    Result_Description
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMaskingGroupMutationVariables,
  APITypes.DeleteMaskingGroupMutation
>;
export const deleteSchedule = /* GraphQL */ `mutation DeleteSchedule(
  $scheduleAction: ScheduleAction
  $input: DeleteScheduleInput
) {
  deleteSchedule(scheduleAction: $scheduleAction, input: $input) {
    error
    message
    result {
      scheduleName
      scheduleArn
      __typename
    }
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteScheduleMutationVariables,
  APITypes.DeleteScheduleMutation
>;
