/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserPrefsInput = {
  username: string,
  inputs?: InputsPrefsInput | null,
  lastSite?: UserPrefSiteInput | null,
  outputs?: OutputsPrefsInput | null,
  readersAlarms?: ReadersAlarmsPrefsInput | null,
  readersModes?: ReadersModesPrefsInput | null,
  schedules?: SchedulesPrefsInput | null,
  siteTemplates?: SiteTemplatesPrefsInput | null,
  site?: string | null,
  sites?: Array< UserPrefSiteInput | null > | null,
};

export type InputsPrefsInput = {
  numRecordsPerPage?: number | null,
  emailAddresses?: string | null,
  savedSearches?: Array< InputsSavedSearchInput | null > | null,
};

export type InputsSavedSearchInput = {
  name: string,
  filterText?: string | null,
  maskStatus?: string | null,
  modes?: Array< string | null > | null,
};

export type UserPrefSiteInput = {
  sitename: string,
  siteRegion?: string | null,
};

export type OutputsPrefsInput = {
  numRecordsPerPage?: number | null,
  emailAddresses?: string | null,
  savedSearches?: Array< OutputsSavedSearchInput | null > | null,
};

export type OutputsSavedSearchInput = {
  name: string,
  filterText?: string | null,
  outputStatus?: string | null,
};

export type ReadersAlarmsPrefsInput = {
  numRecordsPerPage?: number | null,
  emailAddresses?: string | null,
  savedSearches?: Array< ReadersAlarmsSavedSearchInput | null > | null,
};

export type ReadersAlarmsSavedSearchInput = {
  name: string,
  filterText?: string | null,
  maskStatus?: string | null,
  modes?: Array< string | null > | null,
};

export type ReadersModesPrefsInput = {
  numRecordsPerPage?: number | null,
  emailAddresses?: string | null,
  savedSearches?: Array< ReadersSavedSearchInput | null > | null,
};

export type ReadersSavedSearchInput = {
  name: string,
  filterText?: string | null,
  maskStatus?: string | null,
  modes?: Array< string | null > | null,
};

export type SchedulesPrefsInput = {
  numRecordsPerPage?: number | null,
  emailAddresses?: string | null,
  visibleColumns?: Array< string | null > | null,
};

export type SiteTemplatesPrefsInput = {
  numRecordsPerPage?: number | null,
  visibleColumns?: Array< string | null > | null,
};

export type UserPrefs = {
  __typename: "UserPrefs",
  username: string,
  inputs?: InputsPrefs | null,
  outputs?: OutputsPrefs | null,
  lastSite?: UserPrefSite | null,
  readersAlarms?: ReadersAlarmsPrefs | null,
  readersModes?: ReadersModesPrefs | null,
  schedules?: SchedulesPrefs | null,
  siteTemplates?: SiteTemplatesPrefs | null,
  site?: string | null,
  sites?:  Array<UserPrefSite | null > | null,
};

export type InputsPrefs = {
  __typename: "InputsPrefs",
  numRecordsPerPage?: number | null,
  emailAddresses?: string | null,
  savedSearches?:  Array<InputsSavedSearch | null > | null,
};

export type InputsSavedSearch = {
  __typename: "InputsSavedSearch",
  name: string,
  filterText?: string | null,
  maskStatus?: string | null,
  modes?: Array< string | null > | null,
};

export type OutputsPrefs = {
  __typename: "OutputsPrefs",
  numRecordsPerPage?: number | null,
  emailAddresses?: string | null,
  savedSearches?:  Array<OutputsSavedSearch | null > | null,
};

export type OutputsSavedSearch = {
  __typename: "OutputsSavedSearch",
  name: string,
  filterText?: string | null,
  outputStatus?: string | null,
};

export type UserPrefSite = {
  __typename: "UserPrefSite",
  sitename: string,
  siteRegion?: string | null,
};

export type ReadersAlarmsPrefs = {
  __typename: "ReadersAlarmsPrefs",
  numRecordsPerPage?: number | null,
  emailAddresses?: string | null,
  savedSearches?:  Array<ReadersAlarmsSavedSearch | null > | null,
};

export type ReadersAlarmsSavedSearch = {
  __typename: "ReadersAlarmsSavedSearch",
  name: string,
  filterText?: string | null,
  maskStatus?: string | null,
  modes?: Array< string | null > | null,
};

export type ReadersModesPrefs = {
  __typename: "ReadersModesPrefs",
  numRecordsPerPage?: number | null,
  emailAddresses?: string | null,
  savedSearches?:  Array<ReadersAlarmsSavedSearch | null > | null,
};

export type SchedulesPrefs = {
  __typename: "SchedulesPrefs",
  numRecordsPerPage?: number | null,
  emailAddresses?: string | null,
  visibleColumns?: Array< string | null > | null,
};

export type SiteTemplatesPrefs = {
  __typename: "SiteTemplatesPrefs",
  numRecordsPerPage?: number | null,
  visibleColumns?: Array< string | null > | null,
};

export type UpdateUserPrefsInput = {
  username: string,
  inputs?: InputsPrefsInput | null,
  lastSite?: UserPrefSiteInput | null,
  outputs?: OutputsPrefsInput | null,
  readersAlarms?: ReadersAlarmsPrefsInput | null,
  readersModes?: ReadersModesPrefsInput | null,
  schedules?: SchedulesPrefsInput | null,
  siteTemplates?: SiteTemplatesPrefsInput | null,
  site?: string | null,
  sites?: Array< UserPrefSiteInput | null > | null,
};

export type DeleteUserPrefsInput = {
  username: string,
};

export enum ScheduleAction {
  delete = "delete",
  list = "list",
  listProgrammed = "listProgrammed",
  set = "set",
  update = "update",
  edit = "edit",
}


export type EditScheduleInput = {
  actionAfterCompletion?: ActionAfterCompletion | null,
  amzn_key?: string | null,
  deviceSource: string,
  enabled?: boolean | null,
  masked?: boolean | null,
  maskingGroupName?: string | null,
  maskingGroupType?: string | null,
  maskingGroupId?: number | null,
  mode?: string | null,
  path: string,
  requestedBy: string,
  scheduleExpression: string,
  siteName: string,
  siteRegion: string,
  timezone: string,
  scheduleName?: string | null,
};

export enum ActionAfterCompletion {
  DELETE = "DELETE",
  NONE = "NONE",
}


export type ScheduleResponse = {
  __typename: "ScheduleResponse",
  error?: string | null,
  message?: string | null,
  result?: ScheduleIdentifier | null,
  status: number,
};

export type ScheduleIdentifier = {
  __typename: "ScheduleIdentifier",
  scheduleName: string,
  scheduleArn?: string | null,
};

export type UserActionInput = {
  actionId: string,
  actionName: string,
  timestamp: string,
  username: string,
  parameters?: string | null,
  ttl?: number | null,
};

export type UserAction = {
  __typename: "UserAction",
  actionId: string,
  actionName: string,
  timestamp: string,
  username: string,
  parameters?: string | null,
  ttl?: number | null,
};

export type SetReaderModeInput = {
  deviceSource: string,
  devices: Array< SetDeviceInput | null >,
  emails: string,
  method: HTTPMethod,
  mode: string,
  path: string,
  processWithDelaySeconds: number,
  requestedBy: string,
  scheduleInvocationRequestId?: string | null,
  scheduleInvocationTimestamp?: string | null,
  scheduleName?: string | null,
  siteName: string,
  siteRegion: string,
};

export type SetDeviceInput = {
  childDeviceId?: string | null,
  deviceName: string,
  deviceHref?: string | null,
  deviceType?: string | null,
  parentDeviceId?: string | null,
  segmentId?: string | null,
};

export enum HTTPMethod {
  delete = "delete",
  get = "get",
  post = "post",
  put = "put",
}


export type SetReaderModeResponse = {
  __typename: "SetReaderModeResponse",
  devices?:  Array<SetDeviceResponse | null > | null,
  message?: string | null,
  requestUUID?: string | null,
  status?: number | null,
};

export type SetDeviceResponse = {
  __typename: "SetDeviceResponse",
  deviceName: string,
  status?: string | null,
};

export type SetInputMaskInput = {
  deviceSource: string,
  devices: Array< SetDeviceInput | null >,
  emails: string,
  masked: boolean,
  method: HTTPMethod,
  path: string,
  processWithDelaySeconds: number,
  requestedBy: string,
  scheduleInvocationRequestId?: string | null,
  scheduleInvocationTimestamp?: string | null,
  scheduleName?: string | null,
  siteName: string,
  siteRegion: string,
};

export type SetInputMaskResponse = {
  __typename: "SetInputMaskResponse",
  devices?:  Array<SetDeviceResponse | null > | null,
  message?: string | null,
  requestUUID?: string | null,
  status?: number | null,
};

export type SetOutputCommandInput = {
  deviceSource: string,
  devices: Array< SetDeviceInput | null >,
  emails: string,
  command: string,
  method: HTTPMethod,
  path: string,
  processWithDelaySeconds: number,
  requestedBy: string,
  siteName: string,
  siteRegion: string,
};

export type SetOutputCommandResponse = {
  __typename: "SetOutputCommandResponse",
  devices?:  Array<SetDeviceResponse | null > | null,
  message?: string | null,
  requestUUID?: string | null,
  status?: number | null,
};

export type SetReaderDoorHeldMaskInput = {
  deviceSource: string,
  devices: Array< SetDeviceInput | null >,
  emails: string,
  masked: boolean,
  method: HTTPMethod,
  path: string,
  processWithDelaySeconds: number,
  requestedBy: string,
  scheduleInvocationRequestId?: string | null,
  scheduleInvocationTimestamp?: string | null,
  scheduleName?: string | null,
  siteName: string,
  siteRegion: string,
};

export type SetReaderDoorHeldMaskResponse = {
  __typename: "SetReaderDoorHeldMaskResponse",
  devices?:  Array<SetDeviceResponse | null > | null,
  message?: string | null,
  requestUUID?: string | null,
  status?: number | null,
};

export type SetReaderDoorForcedMaskInput = {
  deviceSource: string,
  devices: Array< SetDeviceInput | null >,
  emails: string,
  masked: boolean,
  method: HTTPMethod,
  path: string,
  processWithDelaySeconds: number,
  requestedBy: string,
  scheduleInvocationRequestId?: string | null,
  scheduleInvocationTimestamp?: string | null,
  scheduleName?: string | null,
  siteName: string,
  siteRegion: string,
};

export type SetReaderDoorForcedMaskResponse = {
  __typename: "SetReaderDoorForcedMaskResponse",
  devices?:  Array<SetDeviceResponse | null > | null,
  message?: string | null,
  requestUUID?: string | null,
  status?: number | null,
};

export type SetScheduleInput = {
  actionAfterCompletion?: ActionAfterCompletion | null,
  amzn_key?: string | null,
  deviceSource: string,
  enabled?: boolean | null,
  masked?: boolean | null,
  maskingGroupName?: string | null,
  maskingGroupType?: string | null,
  maskingGroupId?: number | null,
  mode?: string | null,
  path: string,
  requestedBy: string,
  scheduleExpression: string,
  siteName: string,
  siteRegion: string,
  timezone: string,
};

export type SetPACSDataAPIReaderMaskResponse = {
  __typename: "SetPACSDataAPIReaderMaskResponse",
  status?: number | null,
  actionid?: number | null,
};

export type UpdateScheduleInput = {
  scheduleName: string,
  enabled: boolean,
};

export type PACSMaskingGroupResponse = {
  __typename: "PACSMaskingGroupResponse",
  Result?: string | null,
  Result_Description?: string | null,
};

export type SiteTemplateResponse = {
  __typename: "SiteTemplateResponse",
  Result?: string | null,
  Result_Description?: string | null,
};

export type DeleteScheduleInput = {
  scheduleName: string,
};

export type ChildDevice = {
  __typename: "ChildDevice",
  Child_DeviceID?: number | null,
  Derived_Panel_Windows_Timezone?: string | null,
  device_href?: string | null,
  Device_Type?: string | null,
  DeviceName?: string | null,
  devicesource?: string | null,
  DoorForcedMasked?: boolean | null,
  DoorHeldMasked?: boolean | null,
  Parent_DeviceID?: number | null,
  SegmentID?: number | null,
  Subchild_DeviceID?: number | null,
  Masked?: boolean | null,
  Mode?: string | null,
  MaskGroupID?: string | null,
  MaskGroupName?: string | null,
  MaskGroupType?: string | null,
  pair_primary?: boolean | null,
  pair_secondary?: boolean | null,
  paired_reader_id?: number | null,
  read_only?: boolean | null,
};

export type GetDeviceStatusInput = {
  PARENT_DEVICE_ID: string,
  CHILD_DEVICE_ID: string,
  SUBCHILD_DEVICE_ID: string,
};

export type DeviceStatus = {
  __typename: "DeviceStatus",
  device_key?: string | null,
  parent_id?: number | null,
  child_id?: number | null,
  subchild_id?: number | null,
  site_code?: string | null,
  device_type?: string | null,
  masked?: boolean | null,
  masked_timestamp?: string | null,
  intrusion_report?: string | null,
  intrusion_report_timestamp?: string | null,
  alarm?: boolean | null,
  alarm_timestamp?: string | null,
  line_error?: boolean | null,
  line_error_timestamp?: string | null,
  open_line?: boolean | null,
  open_line_timestamp?: string | null,
  shorted_line?: boolean | null,
  shorted_line_timestamp?: string | null,
  grounded_loop?: boolean | null,
  grounded_loop_timestamp?: string | null,
  relay_contact?: boolean | null,
  relay_contact_timestamp?: string | null,
  comm_loss?: boolean | null,
  comm_loss_timestamp?: string | null,
  comm_loss_host?: boolean | null,
  comm_loss_host_timestamp?: string | null,
  door_forced?: boolean | null,
  door_forced_timestamp?: string | null,
  door_held?: boolean | null,
  door_held_timestamp?: string | null,
  reader_mode?: string | null,
  reader_mode_timestamp?: string | null,
  full_panel_download?: boolean | null,
  full_panel_download_timestamp?: string | null,
  cabinet_tamper?: boolean | null,
  cabinet_tamper_timestamp?: string | null,
  power_failure?: boolean | null,
  power_failure_timestamp?: string | null,
  door_held_masked?: boolean | null,
  door_held_masked_timestamp?: string | null,
  door_forced_masked?: boolean | null,
  door_forced_masked_timestamp?: string | null,
  comm_path?: boolean | null,
  comm_path_timestamp?: string | null,
  last_update_epoch_utc?: string | null,
};

export type LocalIORelation = {
  __typename: "LocalIORelation",
  input_device_child_id: number,
  input_device_parent_id: number,
  input_device_sub_child_id: number,
  output_device_child_id: number,
  output_device_parent_id: number,
  output_device_sub_child_id: number,
};

export type MaskingGroupData = {
  __typename: "MaskingGroupData",
  MaskGroupName?: string | null,
  MaskGroupType?: string | null,
  MaskGroupID?: number | null,
};

export type Site = {
  __typename: "Site",
  site_code: string,
};

export type TemplateDevice = {
  __typename: "TemplateDevice",
  Desired_State: string,
  DeviceName: string,
  Device_Type: string,
  Entity: string,
  EntityType: string,
  MaskGroupName?: string | null,
  MaskGroupType?: string | null,
  TemplateType: string,
  Template_Created_By: string,
  Template_Created_Date_UTC: string,
  amzn_key: string,
  device_href?: string | null,
  devicesource: string,
};

export type ListDeviceStatusForSitesInput = {
  SITE_CODES: string,
};

export type GSODeviceStatusesForSite = {
  __typename: "GSODeviceStatusesForSite",
  Items?: Array< string | null > | null,
  Count?: number | null,
  LastEvaluatedKey?: string | null,
};

export type FacmanUserSites = {
  __typename: "FacmanUserSites",
  sitesTotal?: number | null,
  sites?:  Array<FacmanSite | null > | null,
  error?: string | null,
};

export type FacmanSite = {
  __typename: "FacmanSite",
  siteId: string,
  siteName: string,
  region?: string | null,
  siteType?: string | null,
  maskingApproverEmails?: Array< string | null > | null,
};

export type Notification = {
  __typename: "Notification",
  dismissable: boolean,
  links?:  Array<NotificationLink | null > | null,
  message: string,
  name: string,
  type: NotificationType,
};

export type NotificationLink = {
  __typename: "NotificationLink",
  target: string,
  text: string,
};

export enum NotificationType {
  CRITICAL = "CRITICAL",
  WARNING = "WARNING",
  INFO = "INFO",
}


export type ProgrammedScheduleInput = {
  siteCode: string,
  limit: number,
  offset: number,
};

export type ListScheduleResponse = {
  __typename: "ListScheduleResponse",
  error?: string | null,
  message?: string | null,
  result?: ListSchedulesOutput | null,
  status: number,
};

export type ListSchedulesOutput = {
  __typename: "ListSchedulesOutput",
  nextToken?: string | null,
  schedules?: Array< string | null > | null,
};

export type ListSchedulesInput = {
  NamePrefix: string,
  State?: string | null,
  NextToken?: string | null,
  MaxResults?: number | null,
};

export type SIGInfraSite = {
  __typename: "SIGInfraSite",
  SiteCode: string,
  AccessLevelName: string,
  BadgeKey: number,
  BadgeID: number,
  Login: string,
  Region?: string | null,
};

export type CreateUserPrefsMutationVariables = {
  input: CreateUserPrefsInput,
};

export type CreateUserPrefsMutation = {
  createUserPrefs?:  {
    __typename: "UserPrefs",
    username: string,
    inputs?:  {
      __typename: "InputsPrefs",
      numRecordsPerPage?: number | null,
      emailAddresses?: string | null,
    } | null,
    outputs?:  {
      __typename: "OutputsPrefs",
      numRecordsPerPage?: number | null,
      emailAddresses?: string | null,
    } | null,
    lastSite?:  {
      __typename: "UserPrefSite",
      sitename: string,
      siteRegion?: string | null,
    } | null,
    readersAlarms?:  {
      __typename: "ReadersAlarmsPrefs",
      numRecordsPerPage?: number | null,
      emailAddresses?: string | null,
    } | null,
    readersModes?:  {
      __typename: "ReadersModesPrefs",
      numRecordsPerPage?: number | null,
      emailAddresses?: string | null,
    } | null,
    schedules?:  {
      __typename: "SchedulesPrefs",
      numRecordsPerPage?: number | null,
      emailAddresses?: string | null,
      visibleColumns?: Array< string | null > | null,
    } | null,
    siteTemplates?:  {
      __typename: "SiteTemplatesPrefs",
      numRecordsPerPage?: number | null,
      visibleColumns?: Array< string | null > | null,
    } | null,
    site?: string | null,
    sites?:  Array< {
      __typename: "UserPrefSite",
      sitename: string,
      siteRegion?: string | null,
    } | null > | null,
  } | null,
};

export type UpdateUserPrefsMutationVariables = {
  input: UpdateUserPrefsInput,
};

export type UpdateUserPrefsMutation = {
  updateUserPrefs?:  {
    __typename: "UserPrefs",
    username: string,
    inputs?:  {
      __typename: "InputsPrefs",
      numRecordsPerPage?: number | null,
      emailAddresses?: string | null,
    } | null,
    outputs?:  {
      __typename: "OutputsPrefs",
      numRecordsPerPage?: number | null,
      emailAddresses?: string | null,
    } | null,
    lastSite?:  {
      __typename: "UserPrefSite",
      sitename: string,
      siteRegion?: string | null,
    } | null,
    readersAlarms?:  {
      __typename: "ReadersAlarmsPrefs",
      numRecordsPerPage?: number | null,
      emailAddresses?: string | null,
    } | null,
    readersModes?:  {
      __typename: "ReadersModesPrefs",
      numRecordsPerPage?: number | null,
      emailAddresses?: string | null,
    } | null,
    schedules?:  {
      __typename: "SchedulesPrefs",
      numRecordsPerPage?: number | null,
      emailAddresses?: string | null,
      visibleColumns?: Array< string | null > | null,
    } | null,
    siteTemplates?:  {
      __typename: "SiteTemplatesPrefs",
      numRecordsPerPage?: number | null,
      visibleColumns?: Array< string | null > | null,
    } | null,
    site?: string | null,
    sites?:  Array< {
      __typename: "UserPrefSite",
      sitename: string,
      siteRegion?: string | null,
    } | null > | null,
  } | null,
};

export type DeleteUserPrefsMutationVariables = {
  input: DeleteUserPrefsInput,
};

export type DeleteUserPrefsMutation = {
  deleteUserPrefs?:  {
    __typename: "UserPrefs",
    username: string,
    inputs?:  {
      __typename: "InputsPrefs",
      numRecordsPerPage?: number | null,
      emailAddresses?: string | null,
    } | null,
    outputs?:  {
      __typename: "OutputsPrefs",
      numRecordsPerPage?: number | null,
      emailAddresses?: string | null,
    } | null,
    lastSite?:  {
      __typename: "UserPrefSite",
      sitename: string,
      siteRegion?: string | null,
    } | null,
    readersAlarms?:  {
      __typename: "ReadersAlarmsPrefs",
      numRecordsPerPage?: number | null,
      emailAddresses?: string | null,
    } | null,
    readersModes?:  {
      __typename: "ReadersModesPrefs",
      numRecordsPerPage?: number | null,
      emailAddresses?: string | null,
    } | null,
    schedules?:  {
      __typename: "SchedulesPrefs",
      numRecordsPerPage?: number | null,
      emailAddresses?: string | null,
      visibleColumns?: Array< string | null > | null,
    } | null,
    siteTemplates?:  {
      __typename: "SiteTemplatesPrefs",
      numRecordsPerPage?: number | null,
      visibleColumns?: Array< string | null > | null,
    } | null,
    site?: string | null,
    sites?:  Array< {
      __typename: "UserPrefSite",
      sitename: string,
      siteRegion?: string | null,
    } | null > | null,
  } | null,
};

export type EditScheduleMutationVariables = {
  scheduleAction?: ScheduleAction | null,
  input?: EditScheduleInput | null,
};

export type EditScheduleMutation = {
  editSchedule?:  {
    __typename: "ScheduleResponse",
    error?: string | null,
    message?: string | null,
    result?:  {
      __typename: "ScheduleIdentifier",
      scheduleName: string,
      scheduleArn?: string | null,
    } | null,
    status: number,
  } | null,
};

export type CreateUserActionMutationVariables = {
  input: UserActionInput,
};

export type CreateUserActionMutation = {
  createUserAction?:  {
    __typename: "UserAction",
    actionId: string,
    actionName: string,
    timestamp: string,
    username: string,
    parameters?: string | null,
    ttl?: number | null,
  } | null,
};

export type SetReaderModeMutationVariables = {
  input: SetReaderModeInput,
};

export type SetReaderModeMutation = {
  setReaderMode?:  {
    __typename: "SetReaderModeResponse",
    devices?:  Array< {
      __typename: "SetDeviceResponse",
      deviceName: string,
      status?: string | null,
    } | null > | null,
    message?: string | null,
    requestUUID?: string | null,
    status?: number | null,
  } | null,
};

export type SetInputMaskMutationVariables = {
  input: SetInputMaskInput,
};

export type SetInputMaskMutation = {
  setInputMask?:  {
    __typename: "SetInputMaskResponse",
    devices?:  Array< {
      __typename: "SetDeviceResponse",
      deviceName: string,
      status?: string | null,
    } | null > | null,
    message?: string | null,
    requestUUID?: string | null,
    status?: number | null,
  } | null,
};

export type SetOutputCommandMutationVariables = {
  input: SetOutputCommandInput,
};

export type SetOutputCommandMutation = {
  setOutputCommand?:  {
    __typename: "SetOutputCommandResponse",
    devices?:  Array< {
      __typename: "SetDeviceResponse",
      deviceName: string,
      status?: string | null,
    } | null > | null,
    message?: string | null,
    requestUUID?: string | null,
    status?: number | null,
  } | null,
};

export type SetReaderDoorHeldMaskMutationVariables = {
  input: SetReaderDoorHeldMaskInput,
};

export type SetReaderDoorHeldMaskMutation = {
  setReaderDoorHeldMask?:  {
    __typename: "SetReaderDoorHeldMaskResponse",
    devices?:  Array< {
      __typename: "SetDeviceResponse",
      deviceName: string,
      status?: string | null,
    } | null > | null,
    message?: string | null,
    requestUUID?: string | null,
    status?: number | null,
  } | null,
};

export type SetReaderDoorForcedMaskMutationVariables = {
  input: SetReaderDoorForcedMaskInput,
};

export type SetReaderDoorForcedMaskMutation = {
  setReaderDoorForcedMask?:  {
    __typename: "SetReaderDoorForcedMaskResponse",
    devices?:  Array< {
      __typename: "SetDeviceResponse",
      deviceName: string,
      status?: string | null,
    } | null > | null,
    message?: string | null,
    requestUUID?: string | null,
    status?: number | null,
  } | null,
};

export type SetScheduleMutationVariables = {
  scheduleAction?: ScheduleAction | null,
  input?: SetScheduleInput | null,
};

export type SetScheduleMutation = {
  setSchedule?:  {
    __typename: "ScheduleResponse",
    error?: string | null,
    message?: string | null,
    result?:  {
      __typename: "ScheduleIdentifier",
      scheduleName: string,
      scheduleArn?: string | null,
    } | null,
    status: number,
  } | null,
};

export type SetPACSDataAPIReaderMaskMutationVariables = {
  path: string,
};

export type SetPACSDataAPIReaderMaskMutation = {
  setPACSDataAPIReaderMask?:  {
    __typename: "SetPACSDataAPIReaderMaskResponse",
    status?: number | null,
    actionid?: number | null,
  } | null,
};

export type UpdateScheduleMutationVariables = {
  scheduleAction?: ScheduleAction | null,
  input?: UpdateScheduleInput | null,
};

export type UpdateScheduleMutation = {
  updateSchedule?:  {
    __typename: "ScheduleResponse",
    error?: string | null,
    message?: string | null,
    result?:  {
      __typename: "ScheduleIdentifier",
      scheduleName: string,
      scheduleArn?: string | null,
    } | null,
    status: number,
  } | null,
};

export type UpsertMaskingGroupMutationVariables = {
  path: string,
};

export type UpsertMaskingGroupMutation = {
  upsertMaskingGroup?:  Array< {
    __typename: "PACSMaskingGroupResponse",
    Result?: string | null,
    Result_Description?: string | null,
  } | null > | null,
};

export type UpsertSiteTemplateMutationVariables = {
  path: string,
};

export type UpsertSiteTemplateMutation = {
  upsertSiteTemplate?:  Array< {
    __typename: "SiteTemplateResponse",
    Result?: string | null,
    Result_Description?: string | null,
  } | null > | null,
};

export type DeleteMaskingGroupMutationVariables = {
  path: string,
};

export type DeleteMaskingGroupMutation = {
  deleteMaskingGroup?:  Array< {
    __typename: "PACSMaskingGroupResponse",
    Result?: string | null,
    Result_Description?: string | null,
  } | null > | null,
};

export type DeleteScheduleMutationVariables = {
  scheduleAction?: ScheduleAction | null,
  input?: DeleteScheduleInput | null,
};

export type DeleteScheduleMutation = {
  deleteSchedule?:  {
    __typename: "ScheduleResponse",
    error?: string | null,
    message?: string | null,
    result?:  {
      __typename: "ScheduleIdentifier",
      scheduleName: string,
      scheduleArn?: string | null,
    } | null,
    status: number,
  } | null,
};

export type GetDeviceQueryVariables = {
  path: string,
};

export type GetDeviceQuery = {
  getDevice?:  Array< {
    __typename: "ChildDevice",
    Child_DeviceID?: number | null,
    Derived_Panel_Windows_Timezone?: string | null,
    device_href?: string | null,
    Device_Type?: string | null,
    DeviceName?: string | null,
    devicesource?: string | null,
    DoorForcedMasked?: boolean | null,
    DoorHeldMasked?: boolean | null,
    Parent_DeviceID?: number | null,
    SegmentID?: number | null,
    Subchild_DeviceID?: number | null,
    Masked?: boolean | null,
    Mode?: string | null,
    MaskGroupID?: string | null,
    MaskGroupName?: string | null,
    MaskGroupType?: string | null,
    pair_primary?: boolean | null,
    pair_secondary?: boolean | null,
    paired_reader_id?: number | null,
    read_only?: boolean | null,
  } | null > | null,
};

export type GetDeviceStatusQueryVariables = {
  queryStringParameters: GetDeviceStatusInput,
};

export type GetDeviceStatusQuery = {
  getDeviceStatus?:  {
    __typename: "DeviceStatus",
    device_key?: string | null,
    parent_id?: number | null,
    child_id?: number | null,
    subchild_id?: number | null,
    site_code?: string | null,
    device_type?: string | null,
    masked?: boolean | null,
    masked_timestamp?: string | null,
    intrusion_report?: string | null,
    intrusion_report_timestamp?: string | null,
    alarm?: boolean | null,
    alarm_timestamp?: string | null,
    line_error?: boolean | null,
    line_error_timestamp?: string | null,
    open_line?: boolean | null,
    open_line_timestamp?: string | null,
    shorted_line?: boolean | null,
    shorted_line_timestamp?: string | null,
    grounded_loop?: boolean | null,
    grounded_loop_timestamp?: string | null,
    relay_contact?: boolean | null,
    relay_contact_timestamp?: string | null,
    comm_loss?: boolean | null,
    comm_loss_timestamp?: string | null,
    comm_loss_host?: boolean | null,
    comm_loss_host_timestamp?: string | null,
    door_forced?: boolean | null,
    door_forced_timestamp?: string | null,
    door_held?: boolean | null,
    door_held_timestamp?: string | null,
    reader_mode?: string | null,
    reader_mode_timestamp?: string | null,
    full_panel_download?: boolean | null,
    full_panel_download_timestamp?: string | null,
    cabinet_tamper?: boolean | null,
    cabinet_tamper_timestamp?: string | null,
    power_failure?: boolean | null,
    power_failure_timestamp?: string | null,
    door_held_masked?: boolean | null,
    door_held_masked_timestamp?: string | null,
    door_forced_masked?: boolean | null,
    door_forced_masked_timestamp?: string | null,
    comm_path?: boolean | null,
    comm_path_timestamp?: string | null,
    last_update_epoch_utc?: string | null,
  } | null,
};

export type GetLocalIORelationsQueryVariables = {
  path: string,
};

export type GetLocalIORelationsQuery = {
  getLocalIORelations?:  Array< {
    __typename: "LocalIORelation",
    input_device_child_id: number,
    input_device_parent_id: number,
    input_device_sub_child_id: number,
    output_device_child_id: number,
    output_device_parent_id: number,
    output_device_sub_child_id: number,
  } | null > | null,
};

export type GetMaskingGroupDataQueryVariables = {
  path: string,
};

export type GetMaskingGroupDataQuery = {
  getMaskingGroupData?:  Array< {
    __typename: "MaskingGroupData",
    MaskGroupName?: string | null,
    MaskGroupType?: string | null,
    MaskGroupID?: number | null,
  } | null > | null,
};

export type GetSitesQueryVariables = {
  path: string,
};

export type GetSitesQuery = {
  getSites?:  Array< {
    __typename: "Site",
    site_code: string,
  } | null > | null,
};

export type GetSiteTemplateDevicesQueryVariables = {
  path: string,
};

export type GetSiteTemplateDevicesQuery = {
  getSiteTemplateDevices?:  Array< {
    __typename: "TemplateDevice",
    Desired_State: string,
    DeviceName: string,
    Device_Type: string,
    Entity: string,
    EntityType: string,
    MaskGroupName?: string | null,
    MaskGroupType?: string | null,
    TemplateType: string,
    Template_Created_By: string,
    Template_Created_Date_UTC: string,
    amzn_key: string,
    device_href?: string | null,
    devicesource: string,
  } | null > | null,
};

export type GetUserPrefsQueryVariables = {
  username: string,
};

export type GetUserPrefsQuery = {
  getUserPrefs?:  {
    __typename: "UserPrefs",
    username: string,
    inputs?:  {
      __typename: "InputsPrefs",
      numRecordsPerPage?: number | null,
      emailAddresses?: string | null,
    } | null,
    outputs?:  {
      __typename: "OutputsPrefs",
      numRecordsPerPage?: number | null,
      emailAddresses?: string | null,
    } | null,
    lastSite?:  {
      __typename: "UserPrefSite",
      sitename: string,
      siteRegion?: string | null,
    } | null,
    readersAlarms?:  {
      __typename: "ReadersAlarmsPrefs",
      numRecordsPerPage?: number | null,
      emailAddresses?: string | null,
    } | null,
    readersModes?:  {
      __typename: "ReadersModesPrefs",
      numRecordsPerPage?: number | null,
      emailAddresses?: string | null,
    } | null,
    schedules?:  {
      __typename: "SchedulesPrefs",
      numRecordsPerPage?: number | null,
      emailAddresses?: string | null,
      visibleColumns?: Array< string | null > | null,
    } | null,
    siteTemplates?:  {
      __typename: "SiteTemplatesPrefs",
      numRecordsPerPage?: number | null,
      visibleColumns?: Array< string | null > | null,
    } | null,
    site?: string | null,
    sites?:  Array< {
      __typename: "UserPrefSite",
      sitename: string,
      siteRegion?: string | null,
    } | null > | null,
  } | null,
};

export type ListChildDevicesQueryVariables = {
  path: string,
};

export type ListChildDevicesQuery = {
  listChildDevices?:  Array< {
    __typename: "ChildDevice",
    Child_DeviceID?: number | null,
    Derived_Panel_Windows_Timezone?: string | null,
    device_href?: string | null,
    Device_Type?: string | null,
    DeviceName?: string | null,
    devicesource?: string | null,
    DoorForcedMasked?: boolean | null,
    DoorHeldMasked?: boolean | null,
    Parent_DeviceID?: number | null,
    SegmentID?: number | null,
    Subchild_DeviceID?: number | null,
    Masked?: boolean | null,
    Mode?: string | null,
    MaskGroupID?: string | null,
    MaskGroupName?: string | null,
    MaskGroupType?: string | null,
    pair_primary?: boolean | null,
    pair_secondary?: boolean | null,
    paired_reader_id?: number | null,
    read_only?: boolean | null,
  } | null > | null,
};

export type ListChildDevicesWithReadOnlyQueryVariables = {
  path: string,
};

export type ListChildDevicesWithReadOnlyQuery = {
  listChildDevicesWithReadOnly?:  Array< {
    __typename: "ChildDevice",
    Child_DeviceID?: number | null,
    Derived_Panel_Windows_Timezone?: string | null,
    device_href?: string | null,
    Device_Type?: string | null,
    DeviceName?: string | null,
    devicesource?: string | null,
    DoorForcedMasked?: boolean | null,
    DoorHeldMasked?: boolean | null,
    Parent_DeviceID?: number | null,
    SegmentID?: number | null,
    Subchild_DeviceID?: number | null,
    Masked?: boolean | null,
    Mode?: string | null,
    MaskGroupID?: string | null,
    MaskGroupName?: string | null,
    MaskGroupType?: string | null,
    pair_primary?: boolean | null,
    pair_secondary?: boolean | null,
    paired_reader_id?: number | null,
    read_only?: boolean | null,
  } | null > | null,
};

export type ListDeviceStatusForSitesQueryVariables = {
  queryStringParameters: ListDeviceStatusForSitesInput,
};

export type ListDeviceStatusForSitesQuery = {
  listDeviceStatusForSites?:  Array< {
    __typename: "DeviceStatus",
    device_key?: string | null,
    parent_id?: number | null,
    child_id?: number | null,
    subchild_id?: number | null,
    site_code?: string | null,
    device_type?: string | null,
    masked?: boolean | null,
    masked_timestamp?: string | null,
    intrusion_report?: string | null,
    intrusion_report_timestamp?: string | null,
    alarm?: boolean | null,
    alarm_timestamp?: string | null,
    line_error?: boolean | null,
    line_error_timestamp?: string | null,
    open_line?: boolean | null,
    open_line_timestamp?: string | null,
    shorted_line?: boolean | null,
    shorted_line_timestamp?: string | null,
    grounded_loop?: boolean | null,
    grounded_loop_timestamp?: string | null,
    relay_contact?: boolean | null,
    relay_contact_timestamp?: string | null,
    comm_loss?: boolean | null,
    comm_loss_timestamp?: string | null,
    comm_loss_host?: boolean | null,
    comm_loss_host_timestamp?: string | null,
    door_forced?: boolean | null,
    door_forced_timestamp?: string | null,
    door_held?: boolean | null,
    door_held_timestamp?: string | null,
    reader_mode?: string | null,
    reader_mode_timestamp?: string | null,
    full_panel_download?: boolean | null,
    full_panel_download_timestamp?: string | null,
    cabinet_tamper?: boolean | null,
    cabinet_tamper_timestamp?: string | null,
    power_failure?: boolean | null,
    power_failure_timestamp?: string | null,
    door_held_masked?: boolean | null,
    door_held_masked_timestamp?: string | null,
    door_forced_masked?: boolean | null,
    door_forced_masked_timestamp?: string | null,
    comm_path?: boolean | null,
    comm_path_timestamp?: string | null,
    last_update_epoch_utc?: string | null,
  } | null > | null,
};

export type ListDeviceStatusesForSiteQueryVariables = {
  siteCode: string,
  startKey?: string | null,
};

export type ListDeviceStatusesForSiteQuery = {
  listDeviceStatusesForSite?:  {
    __typename: "GSODeviceStatusesForSite",
    Items?: Array< string | null > | null,
    Count?: number | null,
    LastEvaluatedKey?: string | null,
  } | null,
};

export type ListFacmanUserSitesQueryVariables = {
  sitename?: string | null,
  groupname?: string | null,
  regions?: Array< string | null > | null,
  username?: string | null,
  employeeId?: number | null,
};

export type ListFacmanUserSitesQuery = {
  listFacmanUserSites?:  {
    __typename: "FacmanUserSites",
    sitesTotal?: number | null,
    sites?:  Array< {
      __typename: "FacmanSite",
      siteId: string,
      siteName: string,
      region?: string | null,
      siteType?: string | null,
      maskingApproverEmails?: Array< string | null > | null,
    } | null > | null,
    error?: string | null,
  } | null,
};

export type ListNotificationsQueryVariables = {
};

export type ListNotificationsQuery = {
  listNotifications?:  Array< {
    __typename: "Notification",
    dismissable: boolean,
    links?:  Array< {
      __typename: "NotificationLink",
      target: string,
      text: string,
    } | null > | null,
    message: string,
    name: string,
    type: NotificationType,
  } | null > | null,
};

export type ListProgrammedSchedulesQueryVariables = {
  scheduleAction?: ScheduleAction | null,
  input?: ProgrammedScheduleInput | null,
};

export type ListProgrammedSchedulesQuery = {
  listProgrammedSchedules?:  {
    __typename: "ListScheduleResponse",
    error?: string | null,
    message?: string | null,
    result?:  {
      __typename: "ListSchedulesOutput",
      nextToken?: string | null,
      schedules?: Array< string | null > | null,
    } | null,
    status: number,
  } | null,
};

export type ListSchedulesQueryVariables = {
  scheduleAction?: ScheduleAction | null,
  input?: ListSchedulesInput | null,
};

export type ListSchedulesQuery = {
  listSchedules?:  {
    __typename: "ListScheduleResponse",
    error?: string | null,
    message?: string | null,
    result?:  {
      __typename: "ListSchedulesOutput",
      nextToken?: string | null,
      schedules?: Array< string | null > | null,
    } | null,
    status: number,
  } | null,
};

export type ListSIGInfraUserSitesQueryVariables = {
  path: string,
};

export type ListSIGInfraUserSitesQuery = {
  listSIGInfraUserSites?:  Array< {
    __typename: "SIGInfraSite",
    SiteCode: string,
    AccessLevelName: string,
    BadgeKey: number,
    BadgeID: number,
    Login: string,
    Region?: string | null,
  } | null > | null,
};
